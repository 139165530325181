// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2022_04-module--maineDigest--3z2BH";
export var heroImage = "maine_digest_2022_04-module--heroImage--UdAir";
export var columnWrapper = "maine_digest_2022_04-module--columnWrapper--Gv-2X";
export var headingLogo = "maine_digest_2022_04-module--headingLogo--HqH67";
export var headingWrapper = "maine_digest_2022_04-module--headingWrapper--M2EgK";
export var heading = "maine_digest_2022_04-module--heading--29Em6";
export var instance = "maine_digest_2022_04-module--instance--KWDMP";
export var subheading = "maine_digest_2022_04-module--subheading--MiN7v";
export var textWrapper = "maine_digest_2022_04-module--textWrapper--VJSX8";
export var librarypaper = "maine_digest_2022_04-module--librarypaper--cZr1+";
export var library = "maine_digest_2022_04-module--library--PBScm";
export var latestnewsarticleheadline = "maine_digest_2022_04-module--latestnewsarticleheadline--QVvgB";
export var dateline = "maine_digest_2022_04-module--dateline--ak+0d";
export var whatsNew = "maine_digest_2022_04-module--whatsNew--q84VK";
export var colWrapper = "maine_digest_2022_04-module--colWrapper--4-DWi";
export var leftCol = "maine_digest_2022_04-module--leftCol--2qcxU";
export var rightCol = "maine_digest_2022_04-module--rightCol--LxL5v";
export var photoCaption = "maine_digest_2022_04-module--photoCaption--5q5Fx";
export var afterCaption = "maine_digest_2022_04-module--afterCaption--8AgcT";